<template>
  <div class="mt-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          size="sm"
          variant="primary"
          class="btn-create mt-1 mr-1"
          title="Chiusura Massiva"
          @click="openMassReducedModal()"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Chiusura Massiva
        </b-button>
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)" class="mt-1">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-select
                name="deferred"
                label="Mostra Sospesi"
                :options="deferred"
                v-model="filter.byDeferred"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="salesman_id"
                label="Produttori"
                :options="salesmen"
                v-model="filter.byBroker.id"
                :multiple="true"
                :taggable="salesmen_taggable"
                :closeOnSelect="false"
                :defaultHtmlMultipleSelect="true"
                @changeTaggable="changeTaggable"
              />
            </div>
            <div class="form-group col-md-3">
              <div>
                <b-button-toolbar>
                  <b-button-group>
                    <base-input
                      name="Contraente"
                      label="Contraente"
                      v-model="form.inpt_label_registry"
                      :readonly="true"
                    />
                    <b-button
                      class="mt-4 btn-quick"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      title="Ricerca Veloce"
                      @click="openQuickSearchRegistry"
                      ><b-icon icon="search"></b-icon
                    ></b-button>
                    <b-button
                      v-if="form.inpt_label_registry"
                      class="mt-4 btn-quick ml-1"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      @click="resetRegistryId"
                      title="Elimina Anagrafica"
                      ><b-icon icon="trash"></b-icon
                    ></b-button>
                  </b-button-group>
                </b-button-toolbar>
              </div>
            </div>
            <quick-search-registry-modal
              ref="quickSearchRegistry"
              @input="handleRegistryModalInput"
            >
            </quick-search-registry-modal>
            <div class="form-group col-md-3">
              <span
                v-if="registry_data"
                class="info"
                v-html="toInfoData(registry_data, 'registry')"
              >
              </span>
            </div>
          </b-row>
          <b-row>
            <div class="col-md-3">
              <base-select
                name="insurer"
                label="Compagnia"
                :options="companies"
                v-model="filter.byInsurer.id"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="code"
                label="Modalità rec. sospeso"
                :options="treasuries"
                v-model="filter.byTreasury.id"
                :multiple="true"
              />
            </div>
            <div class="form-group col-md-3">
              <div>
                <b-button-toolbar>
                  <b-button-group>
                    <base-input
                      name="Gruppo Anagrafico"
                      label="Gruppo Anagrafico"
                      v-model="form.inpt_label_group"
                      :readonly="true"
                    />
                    <b-button
                      class="mt-4 btn-quick"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      title="Ricerca Veloce"
                      @click="openQuickSearchGroup"
                      ><b-icon icon="search"></b-icon
                    ></b-button>
                    <b-button
                      v-if="form.inpt_label_group"
                      class="mt-4 btn-quick ml-1"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      @click="resetGroupId"
                      title="Elimina Gruppo"
                      ><b-icon icon="trash"></b-icon
                    ></b-button>
                  </b-button-group>
                </b-button-toolbar>
              </div>
            </div>
            <quick-search-group-modal
              ref="quickSearchGroup"
              @input="handleGroupModalInput"
            ></quick-search-group-modal>
            <div class="form-group col-md-3">
              <span
                v-if="group_data"
                class="info"
                v-html="toInfoData(group_data, 'registry_group_full')"
              >
              </span>
            </div>
          </b-row>
          <b-row>
            <div class="col-md-3">
              <base-datepicker
                name="aperto_dal"
                label="Periodo di registrazione a cassa del sospeso dal"
                v-model="filter.byCalendar.from"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="aperto_al"
                label="Periodo di registrazione a cassa del sospeso al"
                v-model="filter.byCalendar.to"
              />
            </div>
            <div class="form-group col-md-3">
              <div>
                <b-button-toolbar>
                  <b-button-group>
                    <base-input
                      name="Polizza"
                      label="Polizza"
                      v-model="form.inpt_label_policy"
                      :readonly="true"
                    />
                    <b-button
                      class="mt-4 btn-quick"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      title="Ricerca Veloce"
                      @click="openQuickSearchPolicy"
                      ><b-icon icon="search"></b-icon
                    ></b-button>
                    <b-button
                      v-if="form.inpt_label_policy"
                      class="mt-4 btn-quick ml-1"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      @click="resetPolicyId"
                      title="Elimina Polizza"
                      ><b-icon icon="trash"></b-icon
                    ></b-button>
                  </b-button-group>
                </b-button-toolbar>
              </div>
            </div>
            <quick-search-policy-modal
              ref="quickSearchPolicy"
              @input="handlePolicyModalInput"
            ></quick-search-policy-modal>
            <div class="form-group col-md-3">
              <span
                v-if="insurance_policy_data"
                class="info"
                v-html="
                  toInfoData(insurance_policy_data, 'insurance_policy', 0)
                "
              >
              </span>
            </div>
          </b-row>
          <b-row>
            <div class="col-md-3">
              <base-datepicker
                name="incasso_successivo"
                label="Periodo di recupero del sospeso dal"
                v-model="filter.byReduced.after_deferred"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="incasso_precedente"
                label="Periodo di recupero del sospeso al"
                v-model="filter.byReduced.before_deferred"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
          <b-button-group
            v-b-toggle:collapse-1-inner
            class="my-2 filter-button-group"
          >
            <span class="when-open">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            <span class="when-closed">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            Altri Filtri
          </b-button-group>
          <b-collapse id="collapse-1-inner" class="mt-2">
            <b-row>
              <div class="col-md-3">
                <base-input
                  name="Note"
                  label="Note"
                  v-model="filter.byCustom.description.value"
                  placeholder="Inserisci una nota"
                />
              </div>
              <div class="col-md-3">
                <base-select
                  name="code"
                  label="Delega"
                  :options="[
                    { value: '0', text: 'Delega Nostra' },
                    { value: '1', text: 'Delega Altrui' },
                  ]"
                  v-model="filter.byInsurancePolicy.sharing_type"
                />
              </div>
            </b-row>
            <div v-show="customFilters.length > 0">
              <p>Attributi personalizzati</p>
              <b-row v-for="(element, index) in customFilters" :key="index">
                <custom-filter
                  v-model="filter.byAttribute[element.key]"
                  :element="element"
                />
              </b-row>
            </div>
          </b-collapse>
        </b-card>
      </b-form>
    </b-collapse>

    <export-options
      :exportUrl="exportUrl"
      :repository="repository"
      :resource="resource"
      :tableRef="$refs[tableRef]"
      :filter="filter"
      :options="[
        {
          code: 'LISTSOSP',
          label: null,
          formats: ['csv', 'pdf'],
        },
        {
          code: 'TOTASOSP',
          label: null,
          formats: ['csv', 'pdf'],
        },
      ]"
    ></export-options>

    <!-- <button-group-table></button-group-table> -->

    <book-entries-deferred
      :fields="fields"
      :repository="repository"
      :filterOn="{
        byRelations: [
          'byVariousAccounting',
          'byRegistry',
          'byRegistryGroup',
          'byInsurer',
          'byBroker',
          'byInsurancePolicy',
          'byTask',
        ],
      }"
      :resource="resource"
      :filterName="filterName"
      :ref="tableRef"
      :onlyActions="['infomodal']"
      :hasChecksButtonGroup="false"
      hasChecks
      descending
      sortField="book_date"
      :total_deferred="total_deferred"
      @onMassReduced="openMassReducedModal"
    ></book-entries-deferred>
    <mass-reduced-modal
      @save="storeMassReduced"
      :grandtotaldeferred="grandtotaldeferred"
    ></mass-reduced-modal>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import QuickSearchPolicyModal from "@/components/modals/quickSearchPolicy";
import QuickSearchGroupModal from "@/components/modals/quickSearchGroup";
import massReducedModal from "@/components/modals/massReducedModal";
import BookEntriesDeferred from "@/components/tables/BookEntriesDeferred";
// import ButtonGroupTable from "@/components/ButtonGroupTable";
import ExportOptions from "@/components/ExportOptions";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseInput from "@/components/form/BaseInput";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { mapGetters, mapActions } from "vuex";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import { toInfoData /* , toRelationString  */ } from "@/utils/transforms";
import FormMixin from "@/mixins/FormMixin";
import moment from "moment";
moment.locale("it");
import { prepareFilterQueryString } from "@/utils/forms";

export default {
  mixins: [CustomFiltersMixin, FormMixin],
  data() {
    return {
      exportUrl: null,
      filter: this.initFilter(),
      filterName: "filterAccountingDeferred",
      resource: "book_entries",
      repository: "book_entry",
      tableRef: "AccountingDeferredTableRef",
      notes: [],
      companies: [],
      treasuries: [],
      salesmen: [],
      salesmen_taggable: true,
      registry_group_data: null,
      registry_data: null,
      insurance_policy_data: null,
      group_data: null,
      grandtotaldeferred: null,
      total_deferred: {
        isLoading: true,
        total: null,
      },
      form: {
        inpt_label_policy: "",
        inpt_label_registry: "",
        inpt_label_group: "",
        quick_value: "",
      },
      fields: [
        {
          key: "check",
          label: "",
          sortable: false,
          thClass: "check",
          class: "check",
        },
        {
          key: "book_date",
          label: this.getDictionary("book_date", "book_entry"),
          sortable: true,
          sortKey: "book_date",
          formatter: (value) => toLocaleDate(value),
        },
        {
          key: "last_deferred",
          label: this.getDictionary("Ultimo Recupero"),
          sortable: true,
          sortKey: "byView.max",
        },
        {
          key: "insurer.formatted_title",
          label: this.getDictionary("insurer_id", "book_entry"),
          sortable: true,
          sortKey: "byInsurer.title",
        },
        {
          key: "branch",
          label: this.getDictionary("title", "risk_branch"),
        },
        {
          key: "product",
          label: this.getDictionary("insurance_risk"),
        },
        {
          key: "insurance_policy_number",
          label: this.getDictionary("insurance_policy_number", "book_entry"),
          sortable: true,
          sortKey: "byInsurancePolicy.number",
        },
        {
          key: "registry",
          label: this.getDictionary("registry", "book_entry"),
          sortable: true,
          sortKey: "byRegistry.title",
        },
        {
          key: "broker",
          label: this.getDictionary("broker", "book_entry"),
          sortable: true,
          sortKey: "byBroker.BUID",
        },
        {
          key: "note_counter",
          label: this.getDictionary("Note"),
        },
        {
          key: "primary_group",
          label: this.getDictionary("primary_group"),
          sortable: true,
          sortKey: "byRegistryGroup.code",
        },
        {
          key: "gross",
          label: this.getDictionary("gross", "book_entry"),
          sortable: true,
          formatter: (value) => toLocaleCurrency(value),
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          key: "deferred",
          label: this.getDictionary("Da incassare"),
          formatter: (value) => toLocaleCurrency(value),
          tdClass: "text-right",
          thClass: "text-right",
        },
      ],
      deferred: [
        { value: "yes", text: "Aperti e parzialmente aperti" },
        { value: "no", text: "Chiusi e parzialmente recuperati" },
        { value: "reduced", text: "Parzialmente aperti" },
      ],
      coassicurazioni: [
        { value: "Y", text: "SI" },
        { value: "N", text: "NO" },
      ],
    };
  },
  components: {
    // ButtonGroupTable,
    ExportOptions,
    BaseSelect,
    BaseDatepicker,
    BookEntriesDeferred,
    BaseInput,
    QuickSearchRegistryModal,
    QuickSearchPolicyModal,
    QuickSearchGroupModal,
    massReducedModal,
  },
  methods: {
    toInfoData,
    changeTaggable(val) {
      this.salesmen_taggable = val;
    },
    prepareFilterQueryString,
    initFilter() {
      let init = {
        byAttribute: {
          aperto_dal: null,
          aperto_al: null,
          ape_inc: null,
          sospesi_al: null,
        },
        byView: "deferred",
        byRegistry: {
          id: null,
        },
        byInsurancePolicy: {
          id: null,
          shating_type: null,
        },
        byTreasury: {
          id: null,
        },
        byInsurer: {
          id: null,
        },
        byBroker: {
          id: null,
        },
        byRegistryGroup: {
          code: null,
        },
        byCustom: {
          description: {
            value: null,
            likewise: 4,
            filter: "byTask",
          },
        },
        byDeferred: "yes",
        byCalendar: {
          from: null,
          to: null,
        },
        byReduced: {
          after_deferred: null,
          before_deferred: null,
        },
        coassicurazione: null,
      };
      return init;
    },
    storeMassReduced(form) {
      if (this.$refs["AccountingDeferredTableRef"].selectedRows.length) {
        // Elementi selezionati
        let items = this.$refs["AccountingDeferredTableRef"].items;
        let selectedItems = items.filter(
          (item) => item.check && this.totalDeferred(item) !== 0
        );
        if (selectedItems.every((item) => item.book_date <= form.book_date)) {
          // Payload
          let payload = {
            treasury_id: null,
            detail_date: form.book_date,
            gross: null,
            book_entry_id: null,
          };

          // Promise
          let storedeferred = [];
          let deferred_pivot = [];

          Object.values(selectedItems).forEach((item) => {
            storedeferred = [];

            // Forma di pagamento
            payload.gross = this.totalDeferred(item);
            payload.book_entry_id = item.id;
            payload.treasury_id = form.treasury_id;
            let detail = this.store("entry_detail", payload);
            storedeferred.push(detail);

            // Sospeso
            payload.gross = -parseFloat(this.totalDeferred(item).toFixed(2));
            payload.treasury_id = this.getTreasuries()([]).find(
              (treasury) => treasury.code === "SS"
            ).value;
            let deferred = this.store("entry_detail", payload);
            storedeferred.push(deferred);

            // Pivot
            Promise.all(storedeferred)
              .then((response) => {
                //Store Pivot, counter
                let pivotPayload = { entry_detail: {} };
                let deferredId = response.find(
                  (entry_detail) =>
                    entry_detail.data.data.treasury.code === "SS"
                ).data.data.id;
                response
                  .filter(
                    (entry_detail) =>
                      entry_detail.data.data.treasury.code !== "SS"
                  )
                  .forEach(
                    (entry_detail) =>
                      (pivotPayload.entry_detail[entry_detail.data.data.id] =
                        {})
                  );

                deferred_pivot = this.storeEntryDetail(
                  deferredId,
                  "entry_detail",
                  pivotPayload
                );
                this.$refs["AccountingDeferredTableRef"].fetch();
              })
              .catch((error) => {
                let errMsg = this.$getErrorMessage(error);
                this.$showSnackbar({
                  preset: "error",
                  text: `${errMsg}`,
                });
              });
          });
          Promise.all(deferred_pivot)
            .then(() => {
              this.$showSnackbar({
                preset: "success",
                text: `Azione Completata: Chiusura Massiva Completata`,
              });
              this.$refs["AccountingDeferredTableRef"].fetch();
              this.$bvModal.hide(`massReducedModal`);
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        } else {
          this.$showSnackbar({
            preset: "error",
            text: "Non è possibile procedere per la presenza di sospesi con data reg. superiore alla data di chiusura",
          });
        }
      } else {
        this.$showSnackbar({
          preset: "error",
          text: "Devi selezionare almeno una riga",
        });
      }
    },
    storeEntryDetail(deferredEntryDetailId, relation, payload) {
      const Repo = RepositoryFactory.get("entry_detail");
      return Repo.pivot_store(deferredEntryDetailId, relation, payload);
    },
    openMassReducedModal() {
      this.grandtotaldeferred =
        this.$refs["AccountingDeferredTableRef"].grandTotalDeferred;
      this.$bvModal.show("massReducedModal");
    },
    onAdd() {
      this.$router.push({ name: `${this.resource}.create` });
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.exportUrl = this.$refs[this.tableRef].loadFilter(
        this.$refs[this.tableRef].filterName
      );
      // quicksearch
      criteria = {};
      ["quickSearchPolicy", "quickSearchRegistry", "quickSearchGroup"].forEach(
        (element) => {
          criteria[element] = {
            label: "",
            formLabel: "",
            data: null,
            formData: null,
          };
        }
      );
      criteria.quickSearchPolicy.label = this.form.inpt_label_policy;
      criteria.quickSearchPolicy.formLabel = "form.inpt_label_policy";
      criteria.quickSearchPolicy.data = this.insurance_policy_data;
      criteria.quickSearchPolicy.formData = "insurance_policy_data";
      criteria.quickSearchRegistry.label = this.form.inpt_label_registry;
      criteria.quickSearchRegistry.formLabel = "form.inpt_label_registry";
      criteria.quickSearchRegistry.data = this.registry_data;
      criteria.quickSearchRegistry.formData = "registry_data";
      criteria.quickSearchGroup.label = this.form.inpt_label_group;
      criteria.quickSearchGroup.formLabel = "form.inpt_label_group";
      criteria.quickSearchGroup.data = this.group_data;
      criteria.quickSearchGroup.formData = "group_data";
      this.saveQuickSearchByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;

      const qs = this.prepareFilterQueryString(this.filter);
      this.total_deferred = {
        isLoading: true,
        total: null,
      };

      this.$refs[this.tableRef]
        .fetch()
        .then((response) => {
          console.log("response", response);
          const Repo = RepositoryFactory.get("book_entry");
          this.isLoading = true;
          Repo.deferred_total(qs)
            .then((response) => {
              this.total_deferred.total = response.data.deferred_total;
              this.total_deferred.isLoading = false;
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              this.isLoading = false;
            });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onClearFilter(name) {
      this.resetPolicyId();
      this.resetRegistryId();
      this.resetGroupId();
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removeQuickSearchByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // this.onSearch(name);
    },
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    openQuickSearchRegistry() {
      this.filter.byRegistry.id = null;
      this.$bvModal.show("quickSearchRegistryModal");
    },
    handleRegistryModalInput(value) {
      this.filter.byRegistry.id = value.id;
      this.registry_data = value;
      this.form.inpt_label_registry = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    resetRegistryId() {
      this.registry_data = null;
      this.form.inpt_label_registry = null;
      this.filter.byRegistry.id = null;
    },
    handlePolicyModalInput(value) {
      this.form.quick_value = value.id;
      this.filter.byInsurancePolicy.id = value.id;
      this.form.inpt_label_policy = value.number;
      this.insurance_policy_data = value;
      this.$bvModal.hide("quickSearchPolicyModal");
    },
    openQuickSearchPolicy() {
      this.form.quick_value = null;
      this.$bvModal.show("quickSearchPolicyModal");
    },
    resetPolicyId() {
      this.insurance_policy_data = null;
      this.form.inpt_label_policy = null;
      this.filter.byInsurancePolicy.id = null;
    },
    handleGroupModalInput(value) {
      this.filter.byRegistryGroup.code = value.code;
      this.group_data = value;
      this.form.inpt_label_group = this.toInfoData(
        this.group_data,
        "registry_group"
      );
      this.$bvModal.hide("quickSearchGroupModal");
    },
    openQuickSearchGroup() {
      this.filter.byRegistryGroup.code = null;
      this.$bvModal.show("quickSearchGroupModal");
    },
    resetGroupId() {
      this.group_data = null;
      this.form.inpt_label_group = null;
      this.filter.byRegistryGroup.code = null;
    },
    totalDeferred(item) {
      return parseFloat(
        item.entry_details
          .filter((el) => el.treasury.code == "SS")
          .reduce(function (acc, obj) {
            return acc + obj.gross;
          }, 0)
          .toFixed(2)
      );
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("quicksearch", {
      saveQuickSearchByName: "saveByName",
      removeQuickSearchByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    ...mapGetters("auth", {
      getInsurers: "insurers",
      getSalesmen: "salesmen",
      getTreasuries: "treasuries",
    }),
  },
  beforeDestroy() {
    this.onClearFilter(this.filterName);
    /* Fix navigazione all'interno del modulo CONT */
    const filterName = "filterRegistryGroup";
    this.removeFilterByName(filterName);
    this.removePaginationByName(filterName);
    this.removeSortByName(filterName);
  },
  mounted() {
    // this.filter.byDeferred = this.deferred[0].value;  // USARE INIT FILTER !!!!
    this.companies = this.getInsurers();
    this.salesmen = this.getSalesmen();
    this.treasuries = this.getTreasuries()(["SS"]);
  },
};
</script>
<style scoped></style>
