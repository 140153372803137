<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <deposited-entry-details
          :fields="fields"
          :repository="repository"
          :resource="resource"
          @edit="onEdit"
          @destroy="onDestroy"
          :filterName="filterName"
          :ref="tableRef"
          :depositedStatus="deposited_status"
          :onlyActions="['destroy', 'edit']"
          noInnerWidth
          sortField="entry_time"
          noPagination
          noInputPaginator
        >
        </deposited-entry-details>
        <edit-entry-detail-modal
          @save="onUpdateEntryDetail"
          v-model="detail"
          :initialGross="initial_gross"
        ></edit-entry-detail-modal>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import DepositedEntryDetails from "@/components/tables/DepositedEntryDetails";
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import EditEntryDetailModal from "@/components/modals/editEntryDetailModal";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import DetailTabAutofetchTableMixin from "@/mixins/DetailTabAutofetchTableMixin";
import moment from "moment";
moment.locale("it");
import client from "@/repositories/api";

export default {
  mixins: [ShowMixin, FormMixin, DetailTabAutofetchTableMixin],
  name: "Deposited",
  props: {
    resourceId: Number,
  },
  data() {
    return {
      filter: this.initFilter(),
      isLoading: false,
      details: [],
      detail: null,
      deposited_status: null,
      initial_gross: null,
      filterName: "filterModalDepositedDetail",
      repository: "entry_detail",
      resource: "entry_details",
      tableRef: "modalDepositedTable",
      // perPage: 0, // no pagination on tab auto fetch
      args: {},
      fields: [
        {
          key: "treasury.title",
          label: "Cassa",
        },
        {
          key: "gross",
          label: this.getDictionary("Importo", "treasury"),
          sortable: true,
          sortKey: "gross",
          formatter: (value) => toLocaleCurrency(value),
          tdClass: "text-left",
          thClass: "text-left",
        },
        {
          key: "detail_date",
          label: this.getDictionary("Data", "treasury"),
          sortable: true,
          sortKey: "detail_date",
          formatter: (value) => toLocaleDate(value),
        },
      ],
      form: {
        entry_detail: {
          book_entry_id: null,
          treasury_id: null,
          gross: null,
          detail_date: null,
        },
      },
    };
  },
  methods: {
    initFilter() {
      let init = {
        byBookEntry: { id: this.resourceId },
      };
      return init;
    },
    totalDepositedPos(entry_details) {
      return parseFloat(
        entry_details
          .filter((el) => el.treasury.code == "AC" && el.gross > 0)
          .reduce(function (acc, obj) {
            return acc + obj.gross;
          }, 0)
          .toFixed(2)
      );
    },
    totalDepositedNeg(entry_details) {
      return parseFloat(
        entry_details
          .filter((el) => el.treasury.code == "AC" && el.gross < 0)
          .reduce(function (acc, obj) {
            return acc + obj.gross;
          }, 0)
          .toFixed(2)
      );
    },
    onEdit(entry_detail_id) {
      console.log(this.$refs[this.tableRef]);
      const entry_detail = this.details.find(
        (entry_detail) => entry_detail.id == entry_detail_id
      );
      this.detail = entry_detail;
      this.initial_gross = this.$refs[this.tableRef].items.find(
        (item) => item.id === entry_detail_id
      ).gross;
      this.$bvModal.show("editEditDetailModal");
    },
    deleteEntryDetail(id) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.destroy(id);
    },
    updateEntryDetail(id, form) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.update(id, form);
    },
    onDestroy(id) {
      // Item
      const deletedItem = this.$refs[this.tableRef].items.find(
        (item) => item.id === id
      );
      // Related deferred entry detail
      const relatedDepositedItem = this.details.find(
        (detail) => detail.id === deletedItem.pivot.entry_detail_id
      );

      const promises = [];

      //Delete entry detail
      promises.push(this.deleteEntryDetail(deletedItem.id));

      // Delete or update related deferred entry detail
      if (relatedDepositedItem.treasury.code !== "AB") {
        if (relatedDepositedItem.gross === deletedItem.gross) {
          promises.push(this.deleteEntryDetail(relatedDepositedItem.id));
        } else {
          const payload = {
            book_entry_id: relatedDepositedItem.book_entry_id,
            treasury_id: relatedDepositedItem.treasury_id,
            gross: relatedDepositedItem.gross - deletedItem.gross,
            detail_date: relatedDepositedItem.detail_date,
          };
          promises.push(
            this.updateEntryDetail(relatedDepositedItem.id, payload)
          );
        }
      }

      Promise.all(promises)
        .then(() => {
          let criteria = this.filter;
          let name = this.filterName;
          this.saveFilterByName({ name, criteria });
          this.$refs[this.tableRef]
            .fetch()
            .then(() => {
              this.$emit("fetch");
              this.$showSnackbar({
                preset: "success",
                text: `Azione Completata: Recupero Sospeso Eliminato`,
              });
              this.removeFilterByName(name);
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({ preset: "error", text: errMsg });
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onUpdateEntryDetail(detail, initialGross) {
      const diff = parseFloat(initialGross - detail.gross).toFixed(2);
      if (detail.gross >= 0) {
        this.$showSnackbar({
          preset: "error",
          text: `L'importo inserito deve essere negativo`,
        });
        return;
      }
      if (diff > 0 && this.totalDeposited(this.details) + diff > 0) {
        this.$showSnackbar({
          preset: "error",
          text: `L'importo inserito eccede il sospeso residuo`,
        });
        return;
      }

      // Close edit modal
      this.$bvModal.hide("editEditDetailModal");

      // Related Deferred Item
      const relatedDepositedId = this.$refs[this.tableRef].items.find(
        (item) => item.id === this.detail.id
      ).pivot.entry_detail_id;
      const relatedDepositedItem = this.details.find(
        (item) => item.id === relatedDepositedId
      );
      const promises = [];
      // Update entry detail

      const entryDetailPayload = {
        book_entry_id: detail.book_entry_id,
        treasury_id: detail.treasury_id,
        gross: detail.gross,
        detail_date: detail.detail_date,
      };
      promises.push(this.updateEntryDetail(detail.id, entryDetailPayload));

      // Update related deposited entry detail

      const depositedPayload = {
        book_entry_id: relatedDepositedItem.book_entry_id,
        treasury_id: relatedDepositedItem.treasury_id,
        gross: parseFloat(relatedDepositedItem.gross) - diff,
        detail_date: relatedDepositedItem.detail_date,
      };
      promises.push(
        this.updateEntryDetail(relatedDepositedItem.id, depositedPayload)
      );
      console.log("depositedPayload", depositedPayload);

      Promise.all(promises)
        .then(() => {
          let criteria = this.filter;
          let name = this.filterName;
          this.saveFilterByName({ name, criteria });
          this.$refs[this.tableRef]
            .fetch()
            .then(() => {
              this.$emit("fetch");
              this.$showSnackbar({
                preset: "success",
                text: `Azione Completata: Recupero Sospeso Aggiornato`,
              });
              this.removeFilterByName(name);
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({ preset: "error", text: errMsg });
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    depositedMapping(data) {
      this.details = data;
      let details = [];
      data.filter((item) => {
        if (
          item.treasury.code === "AC" &&
          item.treasury.sector.is_abstract.value === "N" &&
          item.gross < 0 &&
          moment(item.detail_date).isSameOrAfter(item.book_entry.book_date) &&
          item.entry_details.length
        ) {
          item.entry_details.forEach((e) => {
            // #1008: la cassa UC: "Recupero acconto" va rinominata perchè sennò si confondono.. ah! LORO si confondono!? noi invece...
            if (e.treasury.code === "UC") {
              e.treasury.title = "Recupero sospeso";
            }
            return details.push(e);
          });
        }
      });
      return details.sort((a, b) => a.entry_time.localeCompare(b.entry_time));
    },
    totalDeposited(data) {
      return parseFloat(
        data
          .filter((el) => el.treasury.code == "AC")
          .reduce(function (acc, obj) {
            return acc + obj.gross;
          }, 0)
          .toFixed(2)
      );
    },
  },
  beforeMount() {
    this.args = { items: { handle: this.depositedMapping } };
    const url = `book_entries/${this.resourceId}/relations/byRegistry/byTreasury/byBreakdown/byBroker`;
    client
      .get(url, {})
      .then((response) => {
        const data = response.data.data;
        console.log("response", response);
        console.log("data", data);
        this.deposited_status =
          this.totalDepositedPos(data.entry_details) +
          this.totalDepositedNeg(data.entry_details)
            ? "Aperto"
            : "Chiuso";
        console.log(`L'acconto è in stato: ${this.deposited_status}.`);
      })
      .catch((error) => {
        console.error(error);
      });
  },
  components: {
    BaseIcon,
    DepositedEntryDetails,
    EditEntryDetailModal,
  },
  computed: {
    book_entry() {
      return "book_entry";
    },
  },
};
</script>
