<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-form @submit.prevent="">
            <details-card
              v-for="(item, index) in detailFields"
              :key="index"
              :header="item.header"
              :edit="item.edit || false"
              :fields="item.fields"
              :beForm="beForm"
              :data="details"
              :repository="item.repository"
            >
            </details-card>

            <b-card header="true" header-tag="header" class="mt-1">
              <div
                slot="header"
                class="d-flex justify-content-between align-items-center"
              >
                <p class="mb-0 d-inline-block align-middle">
                  <b>Recupero Acconto</b>
                </p>
              </div>
              <div v-if="totalDepositedPos() + totalDepositedNeg() != 0">
                <b-row>
                  <div class="col-md-4">
                    <base-datepicker
                      vid="valuta_da"
                      name="Data Recupero Acconto"
                      label="Data Recupero Acconto"
                      v-model="form.detail_date"
                      :min="minDate"
                      @select="onDetailDateSelect"
                      :rules="getRules('detail_date', 'entry_detail')"
                    />
                  </div>
                </b-row>
                <entry-details
                  ref="edRef"
                  :details="dtls"
                  v-model="form"
                  :rules="getRules('gross')"
                  :repository="repository"
                  canDelete
                  enableRemainder
                  amountField="byRemainderFunction"
                  :remainderFunction="totalDeposited"
                  @select="
                    selectCreateEntryDetail(
                      form.book_entries.entry_details,
                      ...arguments
                    )
                  "
                  @unselect="
                    unselectCreateEntryDetail(
                      form.book_entries.entry_details,
                      ...arguments
                    )
                  "
                  @delete="
                    deleteCreateEntryDetail(
                      form.book_entries.entry_details,
                      ...arguments
                    )
                  "
                ></entry-details>
                <b-button
                  type="button"
                  variant="lisaweb"
                  class="mb-2"
                  :disabled="invalid || entryDetailsCreateTotal() == 0"
                  @click="handleSubmit(onSubmit)"
                  >Salva</b-button
                >
                <b-button
                  type="button"
                  variant="lisaweb"
                  class="mb-2"
                  :disabled="
                    invalid ||
                    -entryDetailsCreateTotal() + totalDeposited() <= 0
                  "
                  @click="onRebate()"
                  >Chiudi acconto con abbuono</b-button
                >
              </div>
              <div v-else>
                <b>Non ci sono acconti</b>
              </div>
            </b-card>
          </b-form>
        </validation-observer>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import FormMixin from "@/mixins/FormMixin";
import ShowMixin from "@/mixins/ShowMixin";
import EntryDetails from "@/components/form/EntryDetails";
import DetailsCard from "@/components/DetailsCard";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import EntryDetailsMixin from "@/mixins/EntryDetailsMixin";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import { toInfoData } from "@/utils/transforms";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import { mapGetters } from "vuex";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [ShowMixin, EntryDetailsMixin, FormMixin],
  name: "General",
  props: {
    resourceId: Number,
  },
  components: {
    BaseIcon,
    EntryDetails,
    BaseDatepicker,
    DetailsCard,
  },
  data() {
    return {
      details: null,
      repository: "book_entry",
      canEdit: false,
      generalLabels: null,
      entryDetails: [],
      treasuriesCode: [],
      extraTreasuries: ["AB"],
      onlyRealTreasuries: true,
      skipAgencyTreasuries: true,
      skipTreasuries: this.getExcludedTreasuries()("OTHER"),
      detailFields: {
        book_entry: {
          header: "Movimento Contabile e Anagrafica",
          edit: false,
          repository: "book_entry",
          fields: [
            {
              label: this.getDictionary("book_date", "book_entry"),
              value: "book_date",
              type: "datepicker",
            },
            {
              label: this.getDictionary("Tipo Acconto"),
              value: "deposit_type",
              virtual: (detail) => {
                return detail.registries.length ? "Cliente" : "Produttore";
              },
            },
            {
              label: this.getDictionary("Cliente"),
              value: "registry",
              virtual: (detail) => {
                return detail.registries.length
                  ? detail.registries[0].status_registry.value === 0
                    ? `${detail.registries[0].attributables.SURN} ${detail.registries[0].attributables.NAME}`
                    : `${detail.registries[0].attributables.CNAM}`
                  : "";
              },
            },
            {
              label: this.getDictionary("salesman"),
              value: "salesman",
              virtual: (detail) => {
                return this.toInfoData(
                  detail.brokers[0],
                  "broker",
                  0,
                  null,
                  "\n"
                );
              },
            },
            {
              label: this.getDictionary("title", "book_entry"),
              value: "title",
            },
          ],
        },
        deposit: {
          header: "Acconto",
          edit: false,
          repository: "book_entry",
          fields: [
            {
              label: this.getDictionary("status", "advance_payment"),
              value: "status",
              virtual: () => {
                return this.totalDepositedPos() + this.totalDepositedNeg()
                  ? "Aperto"
                  : "Chiuso";
              },
            },
            {
              label: this.getDictionary("total_deposit", "advance_payment"),
              value: "total_deposit",
              virtual: () => {
                return this.toLocaleCurrency(this.totalDepositedPos());
              },
            },
            {
              label: this.getDictionary("negative_deposit", "advance_payment"),
              value: "negative_deposit",
              virtual: () => {
                return this.toLocaleCurrency(this.totalDepositedNeg());
              },
            },
            {
              label: this.getDictionary("diff_deposit", "advance_payment"),
              value: "diff_deposit",
              virtual: () => {
                return this.toLocaleCurrency(
                  this.totalDepositedPos() + this.totalDepositedNeg()
                );
              },
            },
            {
              label: this.getDictionary("title", "rebate"),
              value: "rebate",
              virtual: () => {
                return this.toLocaleCurrency(this.toRebate());
              },
            },
          ],
        },
      },
      form: {
        detail_date: moment().format("YYYY-MM-DD"),
        entry_detail: {
          book_entry_id: null,
          treasury_id: null,
          gross: null,
          detail_date: moment().format("YYYY-MM-DD"),
        },
        book_entries: {
          entry_details: {},
        },
        book_entry: {
          total_gross: 6666.66, // TEST
        },
      },
    };
  },
  methods: {
    toInfoData,
    toLocaleCurrency,
    moment,
    totalDeposited() {
      return -(this.totalDepositedPos() + this.totalDepositedNeg());
    },
    totalDepositedPos() {
      return parseFloat(
        this.details.entry_details
          .filter((el) => el.treasury.code == "AC" && el.gross > 0)
          .reduce(function (acc, obj) {
            return acc + obj.gross;
          }, 0)
          .toFixed(2)
      );
    },
    totalDepositedNeg() {
      return parseFloat(
        this.details.entry_details
          .filter((el) => el.treasury.code == "AC" && el.gross < 0)
          .reduce(function (acc, obj) {
            return acc + obj.gross;
          }, 0)
          .toFixed(2)
      );
    },
    toRebate() {
      let deferredIds = this.getDepositedIds(this.details);
      for (const id of deferredIds) {
        let deferredEntryDetail = this.details.entry_details.find(
          (e) => e.id === id
        );
        if (deferredEntryDetail) {
          const found = deferredEntryDetail.entry_details.find(
            (e) => e.treasury.code === "AB"
          );
          if (found) {
            return found.gross;
          }
        }
      }
      return 0;
    },
    getDepositedIds(item) {
      let ret = item.entry_details
        .filter((e) => e.treasury.code === "AC" && parseFloat(e.gross) < 0)
        .map((e) => e.id);
      return ret;
    },
    fetch() {
      let queryString = `relations/byRegistry/byTreasury/byBreakdown/byBroker`;
      return this.fetchShowForm(this.repository, this.resourceId, queryString)
        .then((response) => {
          const data = response.data.data;
          this.details = data;
          const ed = this.fetchCreateForm(this.rep_entrdtl);
          const be = this.fetchEditForm(this.rep, this.resourceId);
          const re = this.fetchCreateForm("registry");

          if (moment(this.details.book_date).isSameOrAfter(moment())) {
            this.form.detail_date = moment(this.details.book_date).format(
              "YYYY-MM-DD"
            );
          }

          Promise.all([ed, be, re]).then(() => {
            this.initDetailCardFormData();
            this.isLoading = false;
          });
        })
        .catch((error) => {
          this.form = {};
          this.isLoading = false;
          console.error(error);
        });
    },
    deleteAll() {
      for (let d of this.dtls.filter((el) => el.selected)) {
        this.deleteCreateEntryDetail(
          this.form.book_entries.entry_details,
          d.id
        );
      }
    },
    entryDetailsCreateTotal() {
      return Object.keys(this.form.book_entries.entry_details)
        .map((key) => {
          return +this.form.book_entries.entry_details[key].amount || 0;
        })
        .reduce(function (sum, i) {
          return sum + i;
        }, 0);
    },
    storeEntryDetailPivot(deferredEntryDetailId, relation, payload) {
      const Repo = RepositoryFactory.get("entry_detail");
      return Repo.pivot_store(deferredEntryDetailId, relation, payload);
    },
    storeEntryDetails() {
      let storeEntryDetail = [];

      let entryDetailPayload = {};
      const filteredEntryDetails = Object.values(
        this.form.book_entries.entry_details
      ).filter(
        (entry_detail) =>
          entry_detail.type !== null && entry_detail.amount !== 0
      );

      // Create Payloads
      const ut = moment().unix();
      filteredEntryDetails.forEach((filtered_entry_detail, index) => {
        entryDetailPayload["book_entry_id"] = this.resourceId;

        entryDetailPayload["gross"] = parseFloat(
          filtered_entry_detail.amount.toFixed(2)
        );

        entryDetailPayload["treasury_id"] = filtered_entry_detail.type;

        entryDetailPayload["detail_date"] = this.setEntryDetailDate(
          filtered_entry_detail,
          filteredEntryDetails
        );

        entryDetailPayload["entry_time"] = moment(ut + index).format("x");

        let dtl = this.store(this.rep_entrdtl, entryDetailPayload);
        storeEntryDetail.push(dtl);
      });
      return storeEntryDetail;
    },
    storeDeferredEntryDetail() {
      let storeDeferredEntryDetail = [];

      let deferredEntryDetailPayload = {};

      // Create Payload
      deferredEntryDetailPayload["book_entry_id"] = this.resourceId;

      deferredEntryDetailPayload["treasury_id"] = this.treasuriesCode.find(
        (treasury) => treasury.code === "AC"
      ).id;

      deferredEntryDetailPayload["gross"] = parseFloat(
        this.entryDetailsCreateTotal().toFixed(2)
      );

      deferredEntryDetailPayload["detail_date"] = this.form.detail_date;

      let dtl = this.store(this.rep_entrdtl, deferredEntryDetailPayload);
      storeDeferredEntryDetail.push(dtl);

      return storeDeferredEntryDetail;
    },
    onRebate() {
      let index = this.dtls.length - 1;
      let rebate_id = this.treasury_options.find(
        (treasury) => treasury.code === "AB"
      ).value;
      this.form.book_entries.entry_details[index].type = rebate_id;
      this.form.book_entries.entry_details[index].amount =
        this.totalDeposited() - this.entryDetailsCreateTotal();
      this.selectCreateEntryDetail(
        this.form.book_entries.entry_details,
        rebate_id,
        index
      );
    },
    onDetailDateSelect(date) {
      // Typed date validity
      if (moment(date, "DD/MM/YYYY").isBefore(this.minDate)) {
        let errMsg = `La data non può essere uguale o precedente a ${toLocaleDate(
          this.minDate
        )}`;
        this.$showSnackbar({
          preset: "info",
          text: `${errMsg}`,
        });
        this.form.detail_date = null;
      }
    },
    hasPositiveTreasuries() {
      return (
        Object.values(this.form.book_entries.entry_details).filter(
          (entry_detail) =>
            entry_detail.amount > 0 &&
            this.treasury_options.find(
              (treasury) => treasury.value == entry_detail.type
            ).code !== "AB"
        ).length !== 0
      );
    },
    setEntryDetailDate(entry_detail, filteredEntryDetails) {
      let rebate_id = this.treasury_options.find(
        (treasury) => treasury.code === "AB"
      ).value;
      // Select last deferred date if entry detail type is 'abbuono'
      if (
        entry_detail.type === rebate_id &&
        filteredEntryDetails.length === 1
      ) {
        // cerco ultimo acconto negativo
        let deposited = this.details.entry_details.filter(
          (entry_detail) =>
            entry_detail.treasury.code === "AC" && entry_detail.gross < 0
        );
        if (deposited.length) {
          return deposited.reduce(
            (a, b) => (a.detail_date > b.detail_date ? a : b).detail_date,
            ""
          );
        } else {
          return this.form.detail_date;
        }
      } else {
        return this.form.detail_date;
      }
    },
    hasRebate() {
      let rebate_id = this.treasury_options.find(
        (treasury) => treasury.code === "AB"
      ).value;

      const found = Object.values(this.form.book_entries.entry_details).find(
        (entry_detail) => entry_detail.type === rebate_id
      );

      return found ? true : false;
    },
    isRebateLast() {
      let rebate_id = this.treasury_options.find(
        (treasury) => treasury.code === "AB"
      ).value;

      const entryDetails = Object.values(
        this.form.book_entries.entry_details
      ).filter(
        (entry_detail) =>
          entry_detail.type !== null && entry_detail.amount !== 0
      );
      return entryDetails[entryDetails.length - 1].type === rebate_id;
    },
    isRebateNegative() {
      let rebate_id = this.treasury_options.find(
        (treasury) => treasury.code === "AB"
      ).value;

      const found = Object.values(this.form.book_entries.entry_details).find(
        (entry_detail) => entry_detail.type === rebate_id
      );

      return found.amount < 0 ? true : false;
    },
    onSubmit() {
      // Remaining must be negative or equal zero
      if (-this.entryDetailsCreateTotal() + this.totalDeposited() <= 0) {
        // Check treasuries
        if (this.hasPositiveTreasuries()) {
          this.$showSnackbar({
            preset: "error",
            text: `L'importo delle forme di pagamento (escluso l'abbuono), deve essere negativo`,
          });
          return;
        }

        // Check rebate conditions
        if (this.hasRebate()) {
          // 'Rebate' treasury cannot be negative
          if (this.isRebateNegative()) {
            this.$showSnackbar({
              preset: "error",
              text: `Forma di pagamento 'abbuono' selezionata: l'abbuono deve essere positivo`,
            });
            return;
          }

          // If there is at least one 'rebate' treasury then the remaining must be zero and the treasury must be the last one
          if (!this.isRebateLast()) {
            this.$showSnackbar({
              preset: "error",
              text: `Forma di pagamento 'abbuono' selezionata: l'abbuono deve essere eselezionato come ultima forma di pagamento`,
            });
            return;
          }

          // If there is at least one 'rebate' treasury then the remaining must be zero
          if (
            this.totalDepositedPos() +
              this.totalDepositedNeg() +
              this.totalDeposited() !==
            0
          ) {
            this.$showSnackbar({
              preset: "error",
              text: `Forma di pagamento 'abbuono' selezionata: il residuo deve essere azzerato`,
            });
            return;
          }
        }

        //Loading
        this.isLoading = true;

        //Store Entry Details
        let entryDetailsPromises = this.storeEntryDetails();
        // Store Related Deposit Treasury
        let deferredEntryDetailPromise = this.storeDeferredEntryDetail();
        //Promises;
        const promises = entryDetailsPromises.concat(
          deferredEntryDetailPromise
        );

        Promise.all(promises)
          .then((response) => {
            // Store pivot
            let payload = { entry_detail: {} };
            let deferredId = response.find(
              (entry_detail) => entry_detail.data.data.treasury.code === "AC"
            ).data.data.id;
            response
              .filter(
                (entry_detail) => entry_detail.data.data.treasury.code !== "AC"
              )
              .forEach(
                (entry_detail) =>
                  (payload.entry_detail[entry_detail.data.data.id] = {})
              );
            // Pivot
            let deferred_pivot = this.storeEntryDetailPivot(
              deferredId,
              "entry_detail",
              payload
            );
            Promise.all([deferred_pivot])
              .then(() => {
                this.$showSnackbar({
                  preset: "success",
                  text: `Azione Completata: Recupero Sospeso Completato`,
                });
                this.deleteAll();
                this.fetch();
                this.$emit("fetch");
                this.isLoading = false;
              })
              .catch((error) => {
                let errMsg = this.$getErrorMessage(error);
                this.$showSnackbar({
                  preset: "error",
                  text: `${errMsg}`,
                });
                this.isLoading = false;
              });
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
            this.isLoading = false;
          });
      } else {
        this.$showSnackbar({
          preset: "error",
          text: `L'importo delle forme di pagamento selezionate supera il l'importo recuperabile`,
        });
        return;
      }
    },
    ...mapGetters("auth", {
      getExcludedTreasuries: "excludedTreasuries",
    }),
  },
  computed: {
    rep() {
      return this.repository;
    },
    rep_entrdtl() {
      return "entry_detail";
    },
    minDate() {
      return moment(this.details.book_date).format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.isLoading = true;
    this.fetch();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card {
  border: none;
}
:deep(.col-md-4.border-bottom) {
  text-transform: uppercase;
  color: rgb(63, 60, 60);
  font-size: bold;
}
</style>
